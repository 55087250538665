<template>
  <div class="main-wrap">
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section-global">
      <img
        v-if="!isPhone"
        src="@/assets/images/Solutions/solutions_banner.png"
        alt="Close-up of Electrify America Employee"
      />
      <img
        v-if="isPhone"
        src="@/assets/images/Solutions/solutions_banner.png"
        alt="Close-up of Electrify America Employee"
      />
      <div class="container">
        <div class="content flex-column">
          <h1 class="heavy">
            We manage every step of the <br />
            electrification process, <br />
            so you don’t have to
          </h1>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">Across execution and maintenance support, we handle projects from start to finish.</p>
          <p class="detail">
            Our expertise spans solutions for fueling centers and service providers, automotive manufacturers, light-,
            medium-, and heavy-duty fleet operators, and utilities.
          </p>
        </div>
      </div>
    </section>
    <section class="two-column-section development">
      <div class="two-column-wrap">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img v-if="!isPhone" src="../assets/images/Solutions/solutions1.png" alt="Trucks carrying containers." />
            <img v-if="isPhone" src="../assets/images/Solutions/solutions1.png" alt="Trucks carrying containers." />
          </div>
          <div class="content">
            <h2 class="header">Our program</h2>
            <p class="details sub-detail">Customized solutions to solve both present and future needs.</p>
            <decorative-list>
              <li>Site identification, selection, and control</li>
              <li>Site planning</li>
              <li>Station completion and permitting</li>
            </decorative-list>
          </div>
        </div>
      </div>
      <div class="two-column-wrap execution">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Solutions/solutions2.png"
              alt="A worker installs a new charging station"
            />
            <img
              v-if="isPhone"
              src="../assets/images/Solutions/solutions2.png"
              alt="A worker installs a new charging station"
            />
          </div>
          <div class="content">
            <h2 class="header">Execution</h2>
            <p class="details sub-detail">
              We scale complex, customizable solutions with speed to turn your project goals into realities.
            </p>
            <decorative-list>
              <li>Siting and commissioning</li>
              <li>Energy management: Battery Energy Storage Systems</li>
              <li>Ongoing service operations and maintenance</li>
            </decorative-list>
          </div>
        </div>
      </div>
      <div class="two-column-wrap">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="content operations">
            <h2 class="header">Operations</h2>
            <p class="detail sub-detail">
              We stay with you even after contracts are signed and hardware is installed: delivering reliability,
              uptime, and industry-leading PlugShare scores.
            </p>
            <div class="operations-flex">
              <div class="group1">
                <h2>Technology and quality engineering</h2>
                <decorative-list>
                  <li>Equipment sourced from reputable, high-quality suppliers</li>
                  <li>Over-the-air updates</li>
                  <li>Predictive analytics and diagnostics</li>
                </decorative-list>
              </div>
              <div class="group2">
                <h2>Customer contact center</h2>
                <decorative-list>
                  <li>24/7 U.S.-based support coverage for the U.S. and Canada</li>
                  <li>Proactively monitor social media channels</li>
                </decorative-list>
              </div>
              <div class="group3">
                <h2>Network operations</h2>
                <decorative-list>
                  <li>Program management and diagnostic support</li>
                </decorative-list>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="two-column-wrap service">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Solutions/solutions3.png"
              alt="Close-up of Electrify America charger"
            />
            <img
              v-if="isPhone"
              src="../assets/images/Solutions/solutions3.png"
              alt="Close-up of Electrify America charger"
            />
          </div>
          <div class="content">
            <h2 class="header">Service and maintenance</h2>
            <p class="details sub-detail">
              Your customers expect things to work the way they’re supposed to. So do we; our dedicated teams keep
              everything in working order.
            </p>
            <decorative-list>
              <li>24/7 dispatch</li>
              <li>Tiered maintenance support</li>
              <li>Warranty management</li>
            </decorative-list>
          </div>
        </div>
      </div>
    </section>
    <img
      src="../assets/images/Solutions/solutions_footer.png"
      alt="an Electrify America charging location at dusk displaying ten chargers pictured from the corner of the lot"
    />
    <section class="contact-section">
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">Electric excellence is possible. Let’s make it happen.</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            All good things (like your ideal customized electric vehicle charging program) begin with a question.
          </p>
          <router-link class="button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import DecorativeList from '../components/DecorativeList.vue';

export default {
  components: { DecorativeList },
  name: 'solutions',
  metaInfo: {
    title: 'Solutions | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content: 'Discover the advantage of innovative solutions customized to meet your goals.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/solutions/' }],
  },
  data() {
    return {
      solutionOne: null,
      solutionTwo: null,
      solutionThree: null,
      solutionFour: null,
      solutionOneDetails: [
        {
          title: 'Charging program development',
          summary: 'We will develop a customized charging program tailored to your unique needs.',
        },
        {
          title: 'Site acquisition',
          summary:
            'With our site acquisition experience, we will find the optimal location for your chargers that factors in the best placement for available power as well as the user experience.',
        },
        {
          title: 'Turnkey solutions',
          summary:
            'We will help provide a charging site layout that maximizes your available space and delivers the charging that your program requires.',
        },
      ],
      solutionTwoDetails: [
        {
          title: 'Charging products',
          summary:
            'We have a product portfolio of EV chargers that range from Ultra-Fast DC to overnight or workplace AC charging. No matter which product you choose, you will get competitive pricing and warranty coverage.',
        },
        {
          title: 'App integration',
          summary:
            'Your organization will have the ability to integrate your chargers within the Electrify America app. With our app solution, EV drivers can find a charging station, start their charging session, pay for their charge, and view their charging history right from their smartphone.',
        },
        {
          title: 'Smart energy management',
          summary:
            'We can help provide you with state-of-the-art battery storage and energy management systems to align your business strategy with local utility rates. This will optimize your cost of operating a charging network.',
        },
        {
          title: 'Construction and commissioning',
          summary:
            'We work with service providers to install your chargers and facilitate fast and efficient interconnection with your local electric utility.',
        },
      ],
      solutionThreeDetails: [
        {
          title: 'Customer care',
          summary:
            'We offer 24/7 U.S.-based customer care for technical issues, billing reconciliations, and mobile app troubleshooting.',
        },
        {
          title: 'Service and maintenance',
          summary:
            'Our Virginia-based Network Operations Center will oversee all your service needs, from monitoring the chargers to dispatching technicians for maintenance. Our emergency dispatch is available 24/7, nationwide.',
        },
        {
          title: 'Revenue management',
          summary:
            'We can manage your charger revenue collection, remotely monitor the health of your charging asset, and provide you with charger usage data.',
        },
        {
          title: 'Training programs',
          summary:
            'We offer online or in-person training on how to use the equipment, along with EV charging fundamentals.',
        },
      ],
      solutionFourDetails: [
        {
          title: 'Wide range of chargers',
          summary:
            'We offer chargers for workplace, residential, and retail space; auto dealerships and manufacturers; light- or heavy-duty fleets; and public charging stations.',
        },
        {
          title: 'Revenue-collecting capabilities',
          summary:
            'If you decide to collect revenue from your chargers, we make it easy. We can help you develop a pricing plan and manage your revenue services.',
        },
        {
          title: 'Smart features',
          summary:
            'App integration, personal-device Wi-Fi connectivity at select EV chargers, and remote start-and-stop capabilities are just a few of the innovative features available.',
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.above(750);
    },
    isTiny() {
      return this.$resize && this.$mq.below(600);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
  methods: {
    expandDetail(index) {
      if (index === 1) {
        this.solutionOne = !this.solutionOne;
      } else if (index === 2) {
        this.solutionTwo = !this.solutionTwo;
      } else if (index === 3) {
        this.solutionThree = !this.solutionThree;
      } else {
        this.solutionFour = !this.solutionFour;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 55px;
  @media only screen and (max-width: 750px) {
    line-height: 1.3;
    font-size: 40px;
  }
}
.hero-section-global {
  .content {
    @media only screen and (min-width: 750px) {
      max-width: 900px;
    }
  }
}

.no-list-style {
  list-style: none;
  margin-left: 0;
  text-indent: 0;
  margin-bottom: 16px;
}

.sub-header {
  margin-bottom: 0px;
  font-weight: 600;
}

.list .detail {
  margin: 0;
}
.main-wrap {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  color: #011452;
  .line-break {
    background-color: #87cc97;
  }
  .detail {
    color: #000000;
    letter-spacing: 0.03px;
    font-size: 16px;
    font-weight: normal;
  }
  .development,
  .contact-section {
    background-color: #fff;
  }
  .execution,
  .service {
    background-color: #f5f5f5;
  }
  .two-column-section {
    .image img {
      width: 350px;
      max-width: 60%;
      margin: 60px auto 0 auto;
      @media screen and (max-width: 1000px) {
        margin-top: 20%;
        max-width: 80%;
      }
      @media screen and (max-width: 750px) {
        margin-top: 30px;
      }
    }
  }
  .operations {
    .detail {
      max-width: 450px;
    }
    .operations-flex {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-top: 50px;
      @media screen and (max-width: 750px) {
        flex-direction: column;
        padding-top: 10px;
      }
      .group1,
      .group2,
      .group3 {
        width: 25%;
        @media screen and (max-width: 750px) {
          width: 100%;
        }
      }
    }
  }
  .service .header {
    line-height: 1.1;
    margin-bottom: 0;
    margin-top: -10px;
    @media screen and (max-width: 750px) {
      margin-top: 30px;
    }
  }
  .contact-section {
    .header {
      font-size: 45px;
      line-height: 1.2;
    }
  }
  .sub-detail {
    color: #000;
    font-weight: 500;
  }
}
</style>
