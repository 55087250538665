<template>
  <article class="terms" :class="{ mobile: isMobile }">
    <h1 class="field__item">Thanks! Now check your email to finish submitting this form.</h1>
    <div
      class="clearfix text-formatted field field--name-field-ea-subtitle field--type-text-long field--label-hidden field__item"
    >
      <p>We received your request, and we've sent a verification email to {{ userEmail }}.</p>
      <p>
        Click the link in the email within the next 48 hours to complete your registration and receive updates from us.
      </p>
    </div>
  </article>
</template>

<script>
export default {
  name: 'verify-email-large',
  metaInfo: {
    title: 'Verify your email sign up | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content: 'Check your email to complete this form and receive updates from us.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>

<style scoped lang="scss">
.field--name-field-ea-subtitle {
  max-width: 1160px;
  margin: 0 auto;
  font-size: 16px;
}
article {
  overflow: hidden;
  position: relative;
  min-height: 650px;
}
</style>
