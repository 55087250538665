export default [
  {
    path: '/featured-project-2',
    redirect: {
      name: 'feature-01-en',
    },
  },
  {
    path: '/featured-project-3',
    redirect: {
      name: 'feature-01-en',
    },
  },
  {
    path: '/featured-project-4',
    redirect: {
      name: 'feature-01-en',
    },
  },
  {
    path: '/featured-project-5',
    redirect: {
      name: 'feature-01-en',
    },
  },
];
