<template>
  <div class="main-wrap">
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section-global">
      <img
        v-if="isPhone"
        src="@/assets/images/Features/Feature1/feature-1-banner-mobile.png"
        alt="Employees walk by a fleet of electric buses"
      />
      <img
        v-else
        src="@/assets/images/Features/Feature1/feature-1-banner.png"
        alt="Employees walk by a fleet of electric buses"
      />
      <div class="container">
        <div class="content flex-column">
          <h1 class="heavy">We’ve powered some of the best&mdash;are you next?</h1>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            In tandem with our expanding public network, we’ve executed bespoke electric vehicle charging solutions—from
            custom charging networks, mobile apps, increased accessibility, and more.
          </p>
          <p class="detail">
            For us, your challenges are opportunities—we thrive on simplifying complex requirements across utilities,
            fleet operators, automotive manufacturers, real estate developers, property owners, retailers, and
            government entities.
          </p>
          <p class="detail">
            As we bring electric vehicle freedom to businesses, we’re ensuring easier access to innovative charging
            solutions.
          </p>
        </div>
      </div>
    </section>
    <section class="image-gallery-global">
      <h2>Featured projects</h2>
      <Carousel :pagination="isPhone" :perPage="isPhone ? 1 : 3" :gap="isPhone ? 0 : 63">
        <template #slide1>
          <div class="card">
            <a href="https://media.electrifyamerica.com/en-us/releases/196" rel="noopener" target="_blank">
              <img
                v-if="isPhone"
                src="@/assets/images/Features/Feature1/4gen-logistics-mobile.png"
                alt="4 Gen Logistics"
              />
              <img v-else src="@/assets/images/Features/Feature1/4gen-logistics-desktop.png" alt="4 Gen Logistics" />
              <section>
                <p>
                  4 Gen <br v-if="isPhone" />
                  Logistics
                </p>
              </section>
            </a>
          </div>
        </template>

        <template #slide2>
          <div class="card">
            <a href="https://media.electrifyamerica.com/en-us/releases/141" rel="noopener" target="_blank">
              <img
                v-if="isPhone"
                src="@/assets/images/Features/Feature1/feature-1-new-york-mobile.png"
                alt="New York Power Authority."
              />
              <img
                v-else
                src="@/assets/images/Features/Feature1/feature-1-new-york.png"
                alt="New York Power Authority."
              />
              <section>
                <p>
                  New York <br v-if="isPhone" />
                  Power Authority
                </p>
              </section>
            </a>
          </div>
        </template>

        <template #slide3>
          <div class="card">
            <a href="https://media.electrifyamerica.com/en-us/releases/191" rel="noopener" target="_blank">
              <img
                v-if="isPhone"
                src="@/assets/images/Features/Feature1/feature-1-ikea-mobile.png"
                alt="An IKEA parking lot with an IKEA van charging at an Electrify America charging station with more charging stations in the background with an electric vehicle charging."
              />
              <img
                v-else
                src="@/assets/images/Features/Feature1/feature-1-ikea.png"
                alt="An IKEA parking lot with an IKEA van charging at an Electrify America charging station with more charging stations in the background with an electric vehicle charging."
              />
              <section>
                <p>IKEA <br v-if="isPhone" />U.S.</p>
              </section>
            </a>
          </div>
        </template>

        <template #slide4>
          <div class="card">
            <a href="https://media.electrifyamerica.com/en-us/releases/127" rel="noopener" target="_blank">
              <img
                v-if="isPhone"
                src="@/assets/images/Features/Feature1/feature-1-arizona-mobile.png"
                alt="Arizona Public Service."
              />
              <img v-else src="@/assets/images/Features/Feature1/feature-1-arizona.png" alt="Arizona Public Service." />
              <section>
                <p>
                  Arizona Public <br v-if="isPhone" />
                  Service
                </p>
              </section>
            </a>
          </div>
        </template>
      </Carousel>
    </section>
    <section class="contact-section contact-feature">
      <div class="container">
        <div class="content flex-column">
          <h2>Electric excellence is possible. Let’s make it happen.</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            All good things (like your ideal customized electric vehicle charging program) begin with a question.
          </p>
          <router-link class="button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Carousel from '@/components/Carousel.vue';

export default {
  name: 'feature-01',
  components: { Carousel },
  metaInfo: {
    title: 'Transforming public transportation | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content:
          'We’re powering electric public transportation in Sacramento by leveraging our community relationships and EV expertise.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/featured-project-1/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
// global css located in App.vue
.main-wrap {
  h1 {
    font-size: 24px;
    max-width: 230px;
    margin: 0 auto;
    line-height: 32px;
    @media screen and (min-width: 750px) {
      font-size: 56px;
      max-width: none;
      line-height: 71px;
    }
    @media (min-width: 1110px) {
      min-width: 1000px;
    }
  }
  h2 {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 24px;
    @media (min-width: 750px) {
      font-size: 40px;
      margin-bottom: 55px;
    }
  }
  .card {
    text-align: center;
    background-color: #f5f5f5;
    border-radius: 13px;

    section {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 98px;
      @media (min-width: 750px) {
        min-height: 128px;
      }
    }
    p {
      color: #011452;
      font-size: 21px;
      font-weight: bold;
      margin: 0;
      @media (min-width: 1100px) {
        font-size: 34px;
      }
    }
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .line-break {
    background-color: #87cc97;
  }
  .detail {
    font-size: 16px;
    letter-spacing: 0.03px;
    color: #000000;
  }
  .image-gallery-global {
    background-color: #fff;
    padding-top: 0;
    @media (max-width: 750px) {
      padding-bottom: 22px;
    }
  }
  .contact-section {
    background-color: #f5f5f5;
    h2 {
      text-align: center;
      margin-top: 30px;
      margin-bottom: 30px;
      font-size: 24px;
      @media (min-width: 750px) {
        font-size: 56px;
        margin-bottom: 0px;
      }
    }
    .content {
      padding-bottom: 136px;
      max-width: 855px;
    }
    .detail {
      color: #000000;
      font-size: 16px;
      letter-spacing: 0.03px;
      max-width: 565px;
      margin: 0;
    }
  }
}

.hero-section-global {
  .content {
    @media only screen and (min-width: 750px) {
      max-width: 950px;
    }
  }
}
</style>
