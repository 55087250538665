<template>
  <div class="main-wrap">
    <!-- Use of this pixel is subject to the Amazon ad specs and policies at http://www.amazon.com/b/?&node=7253015011 -->
    <img
      height="0"
      width="0"
      border="0"
      alt
      src="https://s.amazon-adsystem.com/iui3?d=forester-did&ex-fargs=%3Fid%3D1ba54650-bb4f-9741-9fdf-697694b30237%26type%3DUNKNOWN%26m%3D1&ex-fch=416613&ex-src=https://www.electrifyamerica.com/index&ex-hargs=v%3D1.0%3Bc%3D6544328470501%3Bp%3D1BA54650-BB4F-9741-9FDF-697694B30237"
    />
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section">
      <div class="text-container">
        <div class="container flex-column">
          <h1>
            Powering fast, reliable <br />
            charging solutions <br />
            for your business
          </h1>
          <div class="detail sub-header">
            Electrify Commercial is equipped with everything you need to own <br />
            and operate a reliable DC fast charging network or electrify your fleet.
          </div>
          <router-link :to="{ name: 'contact-us-en' }" class="button">Get Started</router-link>
        </div>
      </div>
    </section>
    <section class="feature-section">
      <div class="flex-column container">
        <h2 class="header">Ready for the future of electrification?<br />Get there with Electrify Commercial.</h2>
        <hr class="line-break" aria-hidden="true" />
        <div class="detail">
          We develop electric vehicle (EV) charging programs tailored to fit your customers’ individual needs. Backed by
          the largest open, Ultra-Fast charging network in the U.S., our experienced team anticipates challenges,
          thoroughly understands charging deployment, and leverages the latest technology. Enter the age of
          electrification with us.
        </div>
      </div>
    </section>
    <section class="stats">
      <div class="container flex-column">
        <h2 class="stats__header">Powered by the largest open, Ultra-Fast network</h2>
        <p class="stats__detail">
          Electrify Commercial leverages the unmatched breadth of the coast-to-coast Electrify America public electric
          vehicle charging network.
        </p>
        <figure class="stats__map">
          <img
            :class="{ 'desktop-img': !isPhone, 'mobile-img': isPhone }"
            srcset="../assets/images/Home/usaMap.gif 840w, ../assets/images/Home/usaMap@2x.gif 1680w"
            sizes="(min-width: 750px) 840px,
            100vw"
            src="../assets/images/Home/usaMap@2x.gif"
            alt="Map of America with hundreds of light blue dots denoting stations in operation or development as of August 2022. The same map has over a hundred light green dots denoting planned station locations by 2026. Network map coverage for illustrative purposes only."
          />
          <caption>
            <div class="caption--current">Current public DC fast charging stations as of August 2022</div>
            <div class="caption--planned">Planned future public DC fast charging stations as of 2026</div>
          </caption>
        </figure>
        <div class="stats__detail">
          Electrify America expects to install or have under development approximately 1,800 total charging stations
          with over 10,000 chargers in the United States and Canada by 2026.
        </div>
      </div>
    </section>
    <img src="../assets/images/Home/footer.png" alt="Charging stations at night." />
    <section class="contact-section">
      <div class="container flex-column">
        <h2 class="header">Electric excellence is possible.<br />Let’s make it happen.</h2>
        <div class="line">
          <hr class="line-break" aria-hidden="true" />
        </div>
        <p class="detail">
          All good things (like your ideal customized electric vehicle charging program) begin with a question.
        </p>
        <router-link class="button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';

export default {
  name: 'home',
  metaInfo: {
    title: 'Home | Electrify Commercial',
    script: [
      {
        type: 'application/ld+json',
        json: {
          '@context': 'http://schema.org',
          '@type': 'Corporation',
          name: 'Electrify Commercial',
          url: 'https://www.electrify-commercial.com/',
          sameAs: [
            'https://twitter.com/electrifyam',
            'https://www.facebook.com/ElectrifyAmerica/',
            'https://www.instagram.com/electrifyamerica/?hl=en',
            'https://www.linkedin.com/company/electrifyamerica/about/',
          ],
          logo: 'https://twitter.com/electrifyam/photo',
          parentOrganization: {
            '@type': 'Corporation',
            name: 'Electrify Commercial',
            url: 'https://www.electrify-commercial.com/',
          },
          contactPoint: [
            {
              '@type': 'ContactPoint',
              telephone: '+1-833-632-2778',
              contactType: 'customer support',
              contactOption: 'TollFree',
              areaServed: 'US',
            },
          ],
        },
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Electrify Commercial can help you develop electric vehicle (EV) charging programs customized for your organization.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/' }],
  },
  data() {
    return {
      data: null,
      site: process.env.VUE_APP_SITE,
      searchParam: null,
    };
  },
  methods: {
    goTo(path) {
      this.$router.push({ name: path });
    },
    popupRef(propData) {
      this.$set(this, 'data', propData);
      this.$emit('ref', {
        num: this.data.num,
        body: this.data.body,
      });
    },
  },
  computed: {
    getLang() {
      return this.$store.state.lang;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.above(750);
    },
    isTiny() {
      return this.$resize && this.$mq.below(600);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>

<style scoped lang="scss">
.main-wrap {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  color: #0b0f4d !important;
  h1 {
    font-size: 75px;
    text-align: left;
    margin-top: 24px;
  }
  .detail {
    font-size: 16px;
    letter-spacing: 0.03px;
    font-weight: normal;
    color: #000000 !important;
  }
  .hero-section {
    display: flex;
    position: relative;
    width: 100%;
    .flex-row {
      width: 100%;
    }
    .text-container {
      width: 100%;
      z-index: 1;
      background-image: url('../assets/images/Home/home_hero.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: 100vh;
      h1 {
        padding-top: 24px;
        text-align: left;
        font-weight: 300;
        font-size: 34px;
        line-height: 35px;
        @media only screen and (min-width: 450px) {
          padding-top: 0;
          font-size: 75px;
          line-height: 70px;
          margin-top: 24px;
        }
        @media only screen and (max-width: 1000px) {
          font-size: 65px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 55px;
          line-height: 1;
        }
        @media only screen and (max-width: 600px) {
          font-size: 45px;
          line-height: 1;
        }
        @media only screen and (max-width: 365px) {
          font-size: 35px;
          line-height: 1;
        }
      }
      .sub-header {
        color: #011352 !important;
      }
      .detail {
        width: 100%;
        margin-top: 24px;
        font-weight: normal;
        @media only screen and (min-width: 450px) {
          margin-top: 24px;
          max-width: 925px;
          font-size: 16px;
          line-height: 24px;
        }
      }
      .container {
        justify-content: center;
      }
    }
  }
  .feature-section {
    margin: 75px 0 32px;
    z-index: 2;
    @media only screen and (min-width: 750px) {
      margin: 105px 0;
    }
    .inline-button {
      align-self: center;
      padding: 1.28em 3em;
      border-radius: 5px;
    }
    .detail {
      display: flex;
      align-self: center;
      text-align: center;
      margin-top: 32px;
      @media only screen and (min-width: 750px) {
        width: 80%;
      }
    }
    .feature-wrap {
      align-self: center;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-top: 32px;
      @media only screen and (min-width: 750px) {
        flex-direction: row;
        width: 80%;
        margin-top: 66px;
      }
      .feature {
        width: 100%;
        display: flex;
        align-items: center;
        margin-right: 16px;
        color: #011352;
        justify-content: flex-start;
        @media only screen and (min-width: 750px) {
          width: 45%;
        }
        img {
          width: 50px;
          height: 50px;
          margin-right: 16px;
        }
        p {
          flex: 1;
        }
      }
    }
  }
  .discover-section {
    background-color: #f8f8f8;
    display: flex;
    flex-direction: column;
    .container {
      height: auto;
    }
    .link-to {
      width: fit-content;
      cursor: pointer;
      font-weight: 600;
    }
    .header-container {
      margin: 40px auto;
      text-align: center;
      @media only screen and (min-width: 750px) {
        margin: 100px auto 50px;
      }
    }
    .two-column-wrap:nth-child(3) {
      background-color: white;
      .image {
        @media only screen and (min-width: 750px) {
          order: 1;
        }
      }
      .content {
        @media only screen and (min-width: 750px) {
          margin-left: 60px;
          margin-right: 0;
          order: 2;
        }
      }
    }
  }
  .stats {
    padding: 60px 0;
    background-color: white;

    @media only screen and (min-width: 750px) {
      padding: 100px 0;
    }
    &__detail,
    &__header {
      align-self: center;
      text-align: center;
    }

    &__detail {
      color: #011352;
      margin: 30px 0 0;
      max-width: 600px;
      width: 100%;

      @media only screen and (min-width: 750px) {
        width: 70%;
      }
    }

    &__header {
      font-size: 40px;

      @media only screen and (max-width: 750px) {
        line-height: 1.3;
      }
    }

    &__map {
      display: flex;
      flex-direction: column;
      margin: 0;

      caption {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        margin: 32px auto;

        .caption--current,
        .caption--planned {
          &::before {
            content: '';
            border-radius: 50%;
            display: inline-block;
            height: 7px;
            margin-right: 9px;
            margin-bottom: 2px;
            width: 7px;
          }
        }

        .caption--current::before {
          background-color: #00afca;
        }
        .caption--planned::before {
          background-color: #76c585;
        }
      }

      img {
        align-self: center;
        margin: 30px 10px;
        max-width: 840px;
        width: 100%;
      }
    }
  }
  .get-started {
    padding: 1.28em 0em;
  }
  .line-break {
    background-color: #87cc97;
    margin: 40px auto 0 auto;
  }
  .contact-section {
    padding: 85px 0;
    .container {
      align-items: center;
    }
    .detail {
      margin: 0 auto;
      margin-top: 30px;
      @media only screen and (min-width: 750px) {
        width: 80%;
      }
    }
  }
}
</style>
