<template>
  <header class="header" ref="headerElement" :class="{ mobile: isMobile }" @mouseenter="dontClear()">
    <div class="main-list">
      <div class="container">
        <div class="left-nav">
          <div v-if="mobilePopUp" @click="toggleMobilePopUp()" class="pagemask" />
          <nav class="nav-container" role="navigation">
            <ul v-if="!searchActive" ref="nav" class="nav">
              <li class="label">
                <button
                  aria-label="Close menu"
                  v-show="mobilePopUp"
                  type="button"
                  ref="closeMenu"
                  class="x-button"
                  @click="toggleMobilePopUp()"
                >
                  <searchCloseSVG />
                </button>
              </li>
              <template v-for="(header, index) of headers">
                <li class="label" :key="index">
                  <router-link
                    :to="{ name: `${header.linkTo}-en` }"
                    :event="header.links ? '' : 'click'"
                    @click.native="mobileDropDown(index)"
                    class="label-name"
                    :class="{
                      onThisPage:
                        $route.name === `${header.linkTo}-en` ||
                        (header.links !== undefined &&
                          header.links.map((link) => `${header.linkTo}-en`).includes($route.name)),
                      open: isMobile && header.links !== undefined && activeMobileDropDowns[index] === true,
                      closed: isMobile && header.links !== undefined && activeMobileDropDowns[index] !== true,
                    }"
                    >{{ header.name.en }}</router-link
                  >
                </li>
              </template>
              <li class="menu-close-container label" v-if="mobilePopUp">
                <button @click="toggleMobilePopUp()" class="sr-only sr-only-focusable menu-close-button">
                  Close Menu
                </button>
              </li>
            </ul>
            <div
              v-show="searchActive"
              class="js-form-item form-item js-form-type-search form-type-search js-form-item-keys form-item-keys form-no-label"
            >
              <input
                ref="search"
                aria-label="Enter the terms you wish to search for."
                autocomplete="off"
                placeholder="SEARCH"
                class="st-default-search-input"
                type="search"
                name="keys"
                value
                size="15"
                maxlength="128"
              />
            </div>
          </nav>
        </div>
        <div class="right-nav">
          <div v-if="!isMobile" class="right-nav-item">
            <a href="https://www.electrifyamerica.com/" rel="noopener" target="_blank"
              >Public<br />
              Charging</a
            >
          </div>
          <div class="search">
            <button
              aria-label="Search"
              class="search-button"
              v-if="!searchActive && !mobilePopUp"
              @click="openSearchbar"
            >
              <searchSVG style="height: 20px" />
            </button>
            <button aria-label="Close search" class="search-button" v-if="searchActive" @click="closeSearchbar">
              <searchCloseSVG style="height: 20px" />
            </button>
          </div>
          <div v-if="!searchActive && isMobile" class="hamburger-container">
            <searchCloseSVG v-if="searchActive && isMobile" @click="searchActive = !searchActive" />
            <button
              aria-label="navigation menu"
              ref="hamburger"
              v-if="isMobile"
              @click="toggleMobilePopUp()"
              class="hamburger"
              :aria-expanded="mobilePopUp.toString()"
            >
              <div class="line" />
              <div class="line" />
              <div class="line" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import searchSVG from '@/assets/images/Header/searchIconWhite.svg';
import searchCloseSVG from '@/assets/images/Global/searchCloseIcon.svg';

export default {
  name: 'Header',
  components: {
    searchSVG,
    searchCloseSVG,
  },
  data() {
    return {
      site: process.env.VUE_APP_SITE,
      activeNav: null,
      inUse: false,
      timeout: null,
      mobilePopUp: false,
      activeMobileDropDowns: {},
      searchActive: false,
      lastScrollTop: 0,
      delta: 5,
      searchIndex: -1,
      headers: [
        {
          name: {
            en: 'Our Advantage',
          },
          linkTo: 'advantages',
        },
        {
          name: {
            en: 'Solutions',
          },
          linkTo: 'solutions',
        },
        {
          name: {
            en: 'Featured Projects',
          },
          linkTo: 'feature-01',
        },
        {
          name: {
            en: 'Nevi Funding',
          },
          linkTo: 'nevi',
        },
        {
          name: {
            en: 'Contact Us',
          },
          linkTo: 'contact-us',
        },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    searchOpen() {
      return require('@/assets/images/Global/searchCloseIcon.svg');
    },
  },
  methods: {
    enter(elParam) {
      const element = elParam;
      const { width } = getComputedStyle(element);
      element.style.width = width;
      element.style.height = 'auto';

      const { height } = getComputedStyle(element);
      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      // eslint-disable-next-line
      getComputedStyle(element).height;

      this.$nextTick(() => {
        element.style.height = height;
      });
    },
    afterEnter(elParam) {
      const element = elParam;
      element.style.height = 'auto';
    },
    updateSearchIndex() {
      let newIndex = -1;
      for (let i = 0; i < this.headers.length; i += 1) {
        if (this.$route.name === `${this.headers[i].linkTo}-en`) {
          newIndex = i;
          break;
        } else if (
          this.headers[i].links &&
          this.headers[i].links.map((link) => `${link.linkTo}-en`).includes(this.$route.name)
        ) {
          newIndex = i;
          break;
        }
      }
      this.searchIndex = newIndex;
    },
    leave(elParam) {
      const element = elParam;
      const { height } = getComputedStyle(element);
      element.style.height = height;

      // eslint-disable-next-line
      getComputedStyle(element).height;

      this.$nextTick(() => {
        element.style.height = 0;
      });
    },

    useFocusTrap() {
      const focusableElementsString =
        'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

      const focusableElements = this.$refs.nav.querySelectorAll(focusableElementsString);

      const firstFocusable = focusableElements[0];

      const lastFocusable = focusableElements[focusableElements.length - 1];

      window.addEventListener('keydown', (e) => {
        if (e.key === 'Tab') {
          if (e.shiftKey) {
            if (document.activeElement === firstFocusable) {
              e.preventDefault();
              lastFocusable.focus();
            }
          } else if (document.activeElement === lastFocusable) {
            e.preventDefault();
            firstFocusable.focus();
          }
        }
      });
    },
    focusOnMenu() {
      setTimeout(() => {
        const closeMenuRef = this.$refs.closeMenu;
        closeMenuRef.focus();
      }, 300);
    },
    focusOnHamburger() {
      setTimeout(() => {
        const hamburgerRef = this.$refs.hamburger;
        hamburgerRef.focus();
      }, 300);
    },
    openSearchbar() {
      const priorFocus = document.activeElement;
      if (!this.searchActive) {
        this.searchActive = true;
      }
      this.$nextTick(() => {
        const { headerElement } = this.$refs;
        const focusableElements = headerElement.querySelectorAll('input, .search-button');
        const deactivate = headerElement.querySelectorAll('a');
        [...deactivate].forEach((el) => el.setAttribute('inert', true));
        const firstFocusable = focusableElements[0];
        const lastFocusable = focusableElements[focusableElements.length - 1];
        const searchRef = this.$refs.search;

        if (this.searchActive) {
          searchRef.focus();
          headerElement.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
              this.closeSearchbar();
              searchRef.value = '';
              priorFocus.focus();
            } else if (e.key === 'Enter') {
              this.updateSearchIndex();
            }

            if (e.key === 'Tab' && this.searchActive) {
              if (e.shiftKey) {
                if (document.activeElement === firstFocusable) {
                  e.preventDefault();
                  lastFocusable.focus();
                }
              } else if (document.activeElement === lastFocusable) {
                e.preventDefault();
                firstFocusable.focus();
              }
            }
          });
        }
      });
    },
    closeSearchbar() {
      const priorFocus = document.activeElement;
      this.searchActive = false;
      const { headerElement } = this.$refs;
      const reactivate = headerElement.querySelectorAll('a');
      [...reactivate].forEach((el) => {
        el.removeAttribute('inert');
      });
      priorFocus.focus();
    },
    toggleMobilePopUp() {
      const backgroundElements = document.querySelectorAll(
        'main, footer, a.logo-container, button.hamburger, a.sr-only, div.grecaptcha-badge, h2, img'
      );
      if (this.mobilePopUp) {
        backgroundElements.forEach((el) => el.removeAttribute('inert'));

        this.$set(this, 'mobilePopUp', false);

        this.$refs.nav.ontransitionend = () => {
          if (!this.mobilePopUp) {
            this.$refs.nav.classList.remove('nav--open');
            this.focusOnHamburger();
          }
        };
        this.$refs.nav.classList.remove('nav--open-animate');
      } else {
        this.$set(this, 'mobilePopUp', true);

        this.$refs.nav.classList.add('nav--open');
        this.$refs.nav.classList.add('nav--open-animate');

        this.$nextTick(() => {
          this.useFocusTrap();
          this.focusOnMenu();
          backgroundElements.forEach((el) => el.setAttribute('inert', 'true'));
        });
      }
    },
    dropDown(index) {
      if (this.headers[index].links !== undefined) {
        if (this.activeNav !== null) {
          this.activeNav.removeAttribute('style');
        }
        [this.activeNav] = this.$refs[`list${index}`];
        this.activeNav.style.transform = 'translateY(0)';
      } else if (!this.isMobile) {
        this.clearDropDownImm();
      }
    },
    mobileDropDown(index) {
      if (this.isMobile) {
        if (this.activeMobileDropDowns[index] === true) {
          this.$set(this.activeMobileDropDowns, index, false);
        } else {
          this.$set(this.activeMobileDropDowns, index, true);
        }
      }
    },
    dontClear() {
      this.initUnderline();
      this.inUse = true;
    },
    clearDropDownImm() {
      if (this.activeNav !== null) {
        this.activeNav.removeAttribute('style');
        this.activeNav.style.transform = null;
        // this.activeNav.style = null;
        this.activeNav = null;
      }
    },
    clearDropDown() {
      this.inUse = false;
      if (this.activeNav !== null) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          if (!this.inUse) {
            this.clearDropDownImm();
          }
        }, 0);
      }
    },
    setUnderlineToElement(index, index2) {
      const [element] = this.$refs[`element${index}${index2}`];
      element.scrollIntoView({ inline: 'center' });
      if (!this.isMobile) {
        const [underline] = this.$refs[`underline${index}`];
        const width = parseFloat(getComputedStyle(element).width);
        const offset = parseFloat(element.offsetLeft);
        const padding = parseFloat(getComputedStyle(element).paddingLeft);
        const halfUnderWidth = parseFloat(getComputedStyle(underline).width) / 2;
        underline.style.transform = `translateX(${offset + padding - halfUnderWidth + width / 2}px)`;
      } else {
        const [underline] = this.$refs[`underline${index}`];
        const width = parseFloat(getComputedStyle(element).width);
        const offset = parseFloat(element.offsetLeft);
        const padding = parseFloat(getComputedStyle(element).paddingLeft);
        const halfUnderWidth = parseFloat(getComputedStyle(underline).width) / 2;
        underline.style.transform = `translateX(${offset + padding - halfUnderWidth + width / 2}px)`;
      }
    },
    initUnderline() {
      this.headers.forEach((header, index) => {
        if (header.links) {
          const index2 = header.links.map((link) => `${link.linkTo}-en`).indexOf(this.$route.name);
          if (index2 === -1) {
            this.setUnderlineToElement(index, 0);
          } else {
            this.setUnderlineToElement(index, index2);
          }
        }
      });
    },
    setNavigationVisibility(isMobile) {
      if (!isMobile) {
        if (this.mobilePopUp) {
          this.toggleMobilePopUp();
        }
        this.$refs.nav.classList.add('nav--desktop');
        this.$refs.nav.classList.remove('nav--mobile');
        this.$refs.nav.classList.remove('nav--open');
      } else {
        this.$refs.nav.classList.remove('nav--desktop');
        this.$refs.nav.classList.add('nav--mobile');
      }
    },
  },
  watch: {
    $resize() {
      this.$nextTick(() => {
        this.initUnderline();
      });
    },
    $route() {
      this.updateSearchIndex();
      this.initUnderline();
      if (this.isMobile) {
        if (this.mobilePopUp) {
          this.toggleMobilePopUp();
        }
      }
      if (this.$route.name === 'home-en' || this.$route.name === 'home-fr') {
        this.clearDropDownImm();
      }
    },
    isMobile(isMobile) {
      this.setNavigationVisibility(isMobile);
    },
  },
  mounted() {
    this.updateSearchIndex();
    // swiftype
    // need to disable linter rules for this outside script
    /* eslint-disable */

    if (!window.PRERENDER_INJECTED) {
      (function (w, d, t, u, n, s, e) {
        w['SwiftypeObject'] = n;
        w[n] =
          w[n] ||
          function () {
            (w[n].q = w[n].q || []).push(arguments);
          };
        s = d.createElement(t);
        e = d.getElementsByTagName(t)[0];
        s.async = 1;
        s.src = u;
        e.parentNode.insertBefore(s, e);
      })(window, document, 'script', '//s.swiftypecdn.com/install/v2/st.js', '_st');

      _st('install', process.env.VUE_APP_SWIFTYPE_KEY, '2.0.0');

      //  OneTrust Cookies Consent Notice start for www.electrif-commercial.com
      const cookiesScript = document.createElement('script');
      cookiesScript.setAttribute('src', 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js');
      cookiesScript.setAttribute('type', 'text/javascript');
      cookiesScript.setAttribute('charset', 'UTF-8');
      cookiesScript.setAttribute('data-domain-script', process.env.VUE_APP_COOKIE_SCRIPT);
      document.head.appendChild(cookiesScript);
      // eslint-disable-next-line
      function OptanonWrapper() {}
      // OneTrust Cookies Consent Notice end for www.electrif-commercial.com
    }
    /* eslint-enable */

    this.setNavigationVisibility(this.isMobile);
  },
};
</script>

<style scoped lang="scss">
* {
  text-transform: uppercase;
}

.header {
  height: 80px;
  a {
    color: white;
  }
  .header-logo {
    width: 125px;
    height: 70px;
    margin-left: -10px;
  }
  .js-form-item {
    min-width: 600px;
    margin-top: 10px;
    text-align: right;
    input {
      background: #0b0f4d;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 0%;
      color: #fff;
      text-transform: none;
      width: 400px;
      -webkit-appearance: none;
      &:focus {
        border: 2px solid rgba(53, 189, 209, 1);
      }
      @media only screen and (max-width: 1130px) {
        width: 80% !important;
      }
    }
    input[type='search']::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }
    input[type='search']::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    /* clears the 'X' from Chrome */
    input[type='search']::-webkit-search-decoration,
    input[type='search']::-webkit-search-cancel-button,
    input[type='search']::-webkit-search-results-button,
    input[type='search']::-webkit-search-results-decoration {
      display: none;
    }
  }
  .main-list {
    position: fixed;
    width: 100%;
    top: 0;
    background-color: #0b0f4d;
    z-index: 3;
    color: white;
    .container {
      display: flex;
      align-items: center;
      max-width: 1160px;
      justify-content: center;
      margin-top: 10px;
      height: 100%;
      max-width: 100vw;
      @media only screen and (min-width: 1130px) {
        padding: 0px;
      }
      svg {
        cursor: pointer;
      }
      .lang-toggle {
        display: flex;
        list-style-type: none;
        margin: 0;
        padding: 0 0 0 20px;
        justify-content: flex-end;
        li {
          padding: 5px;
          line-height: 10px;
          a {
            cursor: pointer;
          }
          &.opaque a {
            opacity: 0.5;
          }
          &:first-child {
            border-right: 1px solid #ffffff;
          }
        }
      }
      .nav-container {
        display: contents;
        font-weight: 500;
      }
      .left-nav {
        display: flex;
        height: 100%;
        flex: 1;
        align-items: center;
        @media only screen and (max-width: 1130px) {
          width: 100%;
        }

        ul {
          padding-left: 30px !important;
          padding-inline-start: 0px;
        }

        .js-form-item {
          display: flex;
          flex: 1;
          text-align: center;
          justify-content: center;

          input {
            display: flex;
            align-self: center;
          }
        }
      }
    }
  }
  .menu-close-button {
    background-color: #001252;
    border: none;
    color: #001252;
    padding: 5px 10px;
    margin-top: 10px;

    &:focus {
      padding: 5px 10px;
      background-color: #fff;
      color: #0b0f4d;
      border-radius: 4px;
      font-weight: 600;
    }
  }
  .menu-close-container {
    list-style-type: none;
  }
  .pagemask {
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 3;
  }
  .right-nav-item {
    border-right: 1px solid rgba(255, 255, 255, 0.3);
    border-left: 1px solid rgba(255, 255, 255, 0.3);
    height: 100%;
    width: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    a {
      text-align: initial;
      cursor: pointer;
      line-height: initial;
    }
  }
  .right-nav {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    .search-button {
      background-color: transparent;
      border: none;
    }
  }
}

.header:not(.mobile) {
  .left-nav {
    ul {
      justify-content: flex-end;
    }
  }
  .main-list {
    height: 80px;
    box-sizing: border-box;
    padding: 0px 19px;
    .container {
      justify-content: space-between;
      max-width: 1287px;
      margin: 0 auto 0 auto;
      .logo-container {
        width: 125px;
        height: 70px;
        display: flex;
        margin-left: -10px;
        svg {
          width: 270px !important;
        }
      }
      .search {
        display: flex;
        margin-left: 30px;
        width: 30px;
      }
      .nav {
        padding: 0;
        display: flex;
        align-items: center;
        position: relative;
        .label {
          list-style-type: none;
          font-size: 14px;
          padding: 0 15px;
          color: white;
          cursor: pointer;
          &:hover,
          .onThisPage {
            color: $c-highlights;
          }
        }
        .label-name {
          font-weight: 500;
        }
      }
    }
  }
}

.header.mobile {
  .left-nav {
    align-items: flex-start;
  }

  .search {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .main-list {
    height: 80px;
    display: flex;
    .container {
      justify-content: space-between;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto 0 auto;
      padding: 0 20px;
      svg {
        padding: 0 10px;
      }
      .js-form-item {
        min-width: 0px;
        text-align: right;
        @media only screen and (max-width: 1130px) {
          text-align: left;
        }
        input {
          width: 100%;
          min-width: 136px;
          box-sizing: border-box;
          height: auto;
        }
      }
      .logo-container {
        width: 115px;
        display: flex;
        flex: 1;
      }
      .hamburger-container,
      .space {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .hamburger,
      .space {
        width: 50px;
        height: 50px;
        box-sizing: border-box;
        padding: 13px 10px;
        display: grid;
        float: right;
        background: transparent;
        border: none;
        .line {
          height: 2px;
          width: 100%;
          background-color: white;
          &:last-child {
            width: 60%;
          }
        }
      }
      .nav {
        position: fixed;
        box-sizing: border-box;
        background-color: #001252;
        display: flex;
        flex-direction: column;
        width: 60%;
        height: 100%;
        right: -80%;
        top: 0;
        margin-block-start: 0;
        padding: 24px 0 100px 40px;
        transform: translateX(0);
        transition: transform 0.3s ease-in-out;
        overflow-y: auto;
        z-index: 4;
        @media only screen and (max-width: 750px) {
          width: 80%;
        }
        @media only screen and (min-width: 750px) and (max-width: 1130px) {
          right: -60%;
          width: 60%;
        }
        &--desktop {
          visibility: visible;
        }

        &--mobile {
          visibility: hidden;
        }

        &--open {
          display: flex;
          visibility: visible;
        }

        &--open-animate {
          transform: translateX(-100%);
        }

        .x-button {
          color: white;
          background: transparent;
          border: none;
          height: 50px;
          width: 50px;
          position: absolute;
          right: 20px;
          top: 0;
        }
        .label {
          text-align: left;
          list-style-type: none;
          .label-name:hover,
          .element:hover,
          .onThisPage {
            color: $c-highlights;
          }
          .label-name {
            font-size: 16px;
            display: block;
            padding: 15px 15px 15px 0;
            font-weight: 500;
            border-radius: 3px;
            &::after {
              position: absolute;
              right: 10%;
            }
            &.open::after {
              content: '-';
            }
            &.closed::after {
              content: '+';
            }
          }
          .slide-enter-active,
          .slide-leave-active {
            transition: height 0.3s ease-in-out;
          }
          .slide-enter,
          .slide-leave-to {
            height: 0;
          }
        }
      }
      .nav-container {
        div {
          display: flex;
          justify-content: center;
          width: 80%;
        }
      }
    }
  }
}
</style>
