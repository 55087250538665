<template>
  <article class="social-media-house-rules" :class="{ mobile: isMobile }">
    <div class="article-body text">
      <div class="container" data-aos="fade-up" v-if="getSite === 'us'">
        <h1>Social Media House Rules</h1>
        <p>
          Welcome to Electrify America! The comments posted by users on this site or any other social network page
          sponsored by Electrify America LLC ("User Content") do not necessarily reflect the opinions or ideals of
          Electrify America, its employees or affiliates. We encourage you to leave comments; however, we will review
          comments and will remove any that are inappropriate or offensive at our discretion. Electrify America is not
          responsible for any User Content, especially the accuracy of any statement about our products or product
          attributes. Electrify America does not endorse any opinions expressed by users, either.
        </p>
        <p>
          Below are the rules for communications and general behavior on social-network pages and online channels
          sponsored by Electrify America:
        </p>
        <ol>
          <li>To comply with Electrify America’s Terms of Use;</li>
          <li>that you will not make any indecent or otherwise inappropriate posts;</li>
          <li>that you will not make any libelous, defamatory, bullying, harassing, threatening, or abusive posts;</li>
          <li>
            that you are the age of 18 or older, unless posting by or with permission of a parent or legal guardian;
          </li>
          <li>
            that you will not invade another person’s privacy or publicity rights, and will not distribute either yours,
            or anyone else’s personal information;
          </li>
          <li>
            that the User Content:
            <br />
            •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}is your own original work;
            <br />
            •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not contain or reference any third party trademarks;
            <br />
            •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not infringe the copyright or any other rights of any third party;
            <br />
            •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}does not contain any illegal content or material; and
            <br />
            •{{ '\u00a0 \u00a0 \u00a0\u00a0' }}you have the permission of all persons shown in the User Content to post
            their image and/or their likeness.
          </li>
          <li>
            and acknowledge that the User Content will appear viewable by visitors to our page and may appear on
            Electrify America’s other websites or in its social media network pages.
          </li>
          <li>
            You grant Electrify America a royal-free and fully-paid license to use and display the User Content
            throughout the world in any type of electronic or digital media for commercial, marketing and/or public
            relations purposes in perpetuity.
          </li>
          <li>
            You consent to allow Electrify America to contact you through email (or as otherwise indicated by you)
            regarding any request Electrify America may have related to obtaining permission from you to use the User
            Content.
          </li>
        </ol>
        <p>Remember: We reserve the right to remove any postings found to be in violation of these rules.</p>
        <p>
          For more information, or to contact customer service, please call our Customer Care Center at 1-833-632-2778,
          or email us at
          <a href="mailto:info@electrifyamerica.com" class="navigate">info@electrifyamerica.com</a>
        </p>
      </div>
      <div class="container" data-aos="fade-up" v-if="getSite === 'ca' && getLang === 'en'">
        <h1>Social Media House Rules</h1>
        <p>
          Welcome to Electrify Canada! The comments posted by users on this site or any other social network page
          sponsored or administered by Electrify Canada ("User Content") do not necessarily reflect the opinions or
          ideals of Electrify Canada, its employees or affiliates. We encourage you to leave comments; however, we
          reserve the right to remove any User Content that we consider to be inappropriate or offensive at our
          discretion. Electrify Canada is not responsible for any User Content and Electrify Canada does not endorse any
          opinions expressed by users.
        </p>

        <p>
          Below are the rules for communications and general behaviour on social-network pages and online channels
          sponsored or administered by Electrify Canada:
        </p>

        <ol>
          <li>
            you will fully comply with
            <router-link :to="{ name: 'terms-en' }" rel="noopener" target="_blank"
              >Electrify Canada’s Terms of Use</router-link
            >;
          </li>
          <li>you will not make any indecent or otherwise inappropriate posts;</li>
          <li>you will not make any libelous, defamatory, bullying, harassing, threatening, or abusive posts;</li>
          <li>
            you are the legal age of majority or older, unless posting by or with permission of a parent or legal
            guardian;
          </li>
          <li>
            you will not invade another person’s privacy or publicity rights, and will not distribute either yours, or
            anyone else’s personal information;
          </li>
          <li>
            any User Content you provide:
            <ul>
              <li>is your own original work;</li>
              <li>does not contain or reference any third party trademarks;</li>
              <li>does not infringe the copyright or any other rights of any third party;</li>
              <li>does not contain any illegal or otherwise inappropriate content or material; and</li>
              <li>
                you have the permission of all persons (and any such person’s parent or legal guardian if they are a
                minor) shown in the User Content to post their image and/or their likeness.
              </li>
            </ul>
          </li>
          <li>
            you acknowledge that any User Content you submit will appear viewable by visitors to our page(s) and may
            appear on Electrify Canada’s other websites or in its social media network pages;
          </li>
          <li>
            you grant Electrify Canada a royalty-free, fully-paid, perpetual non-exclusive license to use and display
            any User Content you submit throughout the world in any type of electronic or digital media for commercial,
            marketing and/or public relations.
          </li>
        </ol>
        <p>
          Remember: We reserve the right, in our discretion, to remove any User Content found to be in violation of
          these rules.
        </p>
        <p>
          For more information, or to contact customer service, please call our Customer Care Center at 1-833-231-3226,
          or email us at
          <a href="mailto:support@electrify-canada.ca" class="navigate"> support@electrify-canada.ca.</a>
        </p>
      </div>
      <div class="container" data-aos="fade-up" v-if="getSite === 'ca' && getLang === 'fr'">
        <h1>Règles internes concernant les réseaux sociaux</h1>
        <p>
          Bienvenue à Electrify Canada! Les commentaires affichés par les utilisateurs sur ce site ou sur toute autre
          page de réseau social parrainée ou administrée par Electrify Canada (« Contenu utilisateur ») ne reflètent pas
          nécessairement les opinions ou les idéaux d’Electrify Canada, de ses employés ou de ses affiliés. Nous vous
          encourageons à laisser des commentaires ; cependant, nous nous réservons le droit de supprimer à notre
          discrétion tout « Contenu utilisateur » que nous jugeons inapproprié ou offensant. Electrify Canada n’est
          responsable d’aucun contenu utilisateur et ne souscrit à aucune opinion exprimée par les utilisateurs.
        </p>
        <p>
          Vous trouverez ci-dessous les règles de communication et de comportement général à respecter sur les pages des
          réseaux sociaux et les canaux en ligne commandités ou administrés par Electrify Canada :
        </p>
        <ol>
          <li>
            vous vous conformerez entièrement aux
            <a href="/fr/mentions-légales" rel="noopener" target="_blank"
              >conditions d’utilisation d’Electrify Canada</a
            >
            ;
          </li>
          <li>vous ne publierez pas de messages indécents ou autrement inappropriés ;</li>
          <li>vous ne publierez aucun message diffamatoire, intimidant, harcelant, menaçant ou abusif ;</li>
          <li>
            vous avez l’âge légal de la majorité ou plus, à moins que les publications soient effectuées par un parent
            ou un tuteur légal ou avec son autorisation ;
          </li>
          <li>
            vous ne porterez pas atteinte à la vie privée ou aux droits de publicité d’une autre personne, et vous ne
            distribuerez pas vos renseignements personnels ou ceux de quiconque ;
          </li>
          <li>
            tout « Contenu utilisateur » que vous fournissez :
            <ul>
              <li>est votre propre œuvre originale ;</li>
              <li>ne contient ni ne fait référence à aucune marque de commerce de tiers ;</li>
              <li>ne porte pas atteinte au droit d’auteur ou à tout autre droit d’un tiers ;</li>
              <li>ne contient aucun contenu ou matériel illégal ou autrement inapproprié ; et</li>
              <li>
                vous avez la permission de toutes les personnes (et de leurs parents ou tuteurs légaux s’ils sont
                mineurs) indiquées dans le « Contenu utilisateur » pour afficher leur image et/ou leur ressemblance.
              </li>
            </ul>
          </li>
          <li>
            vous reconnaissez que tout « Contenu utilisateur » que vous soumettez apparaîtra visible par les visiteurs
            de notre ou nos pages et pourra apparaître sur les autres sites Web d’Electrify Canada ou sur ses pages de
            réseaux sociaux ;
          </li>
          <li>
            vous accordez à Electrify Canada une licence perpétuelle non exclusive, entièrement payée et exempte de
            redevances pour l’utilisation et l’affichage de tout « Contenu utilisateur » que vous soumettez dans le
            monde entier sur tout type de support électronique ou numérique à des fins commerciales, de marketing et/ou
            de relations publiques.
          </li>
        </ol>

        <p>
          À retenir : Nous nous réservons le droit, à notre discrétion, de supprimer tout « Contenu utilisateur » jugé
          en violation de ces règles.
        </p>

        <p>
          Pour de plus amples renseignements ou pour communiquer avec le service à la clientèle, veuillez communiquer
          avec notre Centre de service à la clientèle au 1 833 231-3226 ou par courriel à
          <a href="mailto:support@electrify-canada.ca" class="navigate"> support@electrify-canada.ca.</a>
        </p>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'social-media-house-rules',
  metaInfo: {
    title: 'Social Media House Rules | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content:
          'Electrify America encourages you to leave comments on our websites and social media. Learn how we engage with your comments by reading our Social Media House Rules.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/social-media-house-rules/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    getLang() {
      return this.$store.state.lang;
    },
    getSite() {
      return process.env.VUE_APP_SITE;
    },
  },
};
</script>

<style scoped lang="scss">
a {
  text-decoration: underline;
}
</style>
