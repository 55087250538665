import Vue from 'vue';
import VueGtm from 'vue-gtm';
import { MediaQueries } from 'vue-media-queries';
import AOS from 'aos';
import 'vue-loading-overlay/dist/vue-loading.css';
import Loading from 'vue-loading-overlay';
import VueGtag from 'vue-gtag';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import App from './App.vue';
import store from './store';
import router from './router';
import 'aos/dist/aos.css';
import '@/assets/fonts/fonts.css';

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';

Vue.use(Loading);

const mediaQueries = new MediaQueries();
Vue.use(mediaQueries);
if (!window.PRERENDER_INJECTED) {
  Vue.use(VueReCaptcha, {
    siteKey: process.env.VUE_APP_RECAPTCHA_KEY,
    loaderOptions: {
      autoHideBadge: true,
      explicitRenderParameters: {
        badge: 'bottomleft',
      },
    },
  });
  Vue.use(
    VueGtag,
    {
      config: {
        id: process.env.VUE_APP_GA_CODE_NEW,
      },
      includes: [{ id: process.env.VUE_APP_GA_CODE }],
      onReady() {
        Vue.prototype.$gtagIsLoaded = true;
      },
      onError() {
        Vue.prototype.$gtagIsLoaded = false;
      },
    },
    router
  );
  Vue.use(VueGtm, {
    id: 'GTM-NXVP9FW',
    enabled: true,
    debug: true,
    vueRouter: router,
  });

  // Segment Analytics initialization, uses the environment variable VUE_APP_SEGMENT_KEY
  const segmentScript = document.createElement('script');
  const segmentCode = process.env.VUE_APP_SEGMENT_KEY;
  segmentScript.type = 'text/javascript';
  segmentScript.text =
    '!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="';
  segmentScript.text += segmentCode;
  segmentScript.text += '";;analytics.SNIPPET_VERSION="4.15.3"; analytics.load("';
  segmentScript.text += segmentCode;
  segmentScript.text += '"); }}();';
  document.head.appendChild(segmentScript);
}

new Vue({
  created() {
    AOS.init({
      duration: 700,
    });
  },
  store,
  router,
  mediaQueries,
  render: (h) => h(App),
}).$mount('#app');
