import Vue from 'vue';
import Vuex from 'vuex';
import ApiService from '@/services/Api';
import VueMeta from 'vue-meta';

Vue.use(Vuex);
Vue.use(VueMeta);

export default new Vuex.Store({
  state: {
    lang: 'en',
    email: null,
    currentRefID: null,
  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang;
    },
    setEmail(state, email) {
      state.email = email;
    },
    setCurrentRefID(state, payload) {
      state.currentRefID = payload;
    },
  },
  actions: {
    putSignUp: (context, params) =>
      new Promise((resolve, reject) => {
        ApiService.init();
        ApiService.put('/v1/salesforce/signupForm', params)
          .then((response) => {
            if (response.data) {
              const errorMessage = response.data.errMsg;
              if (errorMessage === null) {
                resolve({
                  status: 'success',
                });
              } else {
                resolve({
                  status: 'error',
                  errorMsg: errorMessage,
                });
              }
            } else {
              resolve({
                status: 'error',
                errorMsg: 'Something went wrong, please try again later',
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }),
    putMarketingOpp: (context, params) =>
      new Promise((resolve, reject) => {
        ApiService.init();
        ApiService.put('/v1/salesforce/marketingOpp', params)
          .then((response) => {
            if (response.data) {
              const errorMessage = response.data.errMsg;
              if (errorMessage === null) {
                resolve({
                  status: 'success',
                });
              } else {
                resolve({
                  status: 'error',
                  errorMsg: errorMessage,
                });
              }
            } else {
              resolve({
                status: 'error',
                errorMsg: 'Something went wrong, please try again later',
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }),
    putContactUs: (context, params) =>
      new Promise((resolve, reject) => {
        ApiService.init();
        ApiService.put('/v1/salesforce/commercialcontactus', params)
          .then((response) => {
            if (response.data) {
              const errorMessage = response.data.errMsg;
              if (errorMessage === null) {
                resolve({
                  status: 'success',
                });
              } else {
                resolve({
                  status: 'error',
                  errorMsg: errorMessage,
                });
              }
            } else {
              resolve({
                status: 'error',
                errorMsg: 'Something went wrong, please try again later',
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }),
    putWarranty: (context, params) =>
      new Promise((resolve, reject) => {
        ApiService.init();
        ApiService.put('/v1/salesforce/warranty', params)
          .then((response) => {
            if (response.data) {
              const errorMessage = response.data.errMsg;
              if (errorMessage === null) {
                resolve({
                  status: 'success',
                });
              } else {
                resolve({
                  status: 'error',
                  errorMsg: errorMessage,
                });
              }
            } else {
              resolve({
                status: 'error',
                errorMsg: 'Something went wrong, please try again later',
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }),
    putNevi: (context, params) =>
      new Promise((resolve, reject) => {
        ApiService.init();
        ApiService.put('/v1/salesforce/nevi', params)
          .then((response) => {
            if (response.data) {
              const errorMessage = response.data.errMsg;
              if (errorMessage === null) {
                resolve({
                  status: 'success',
                });
              } else {
                resolve({
                  status: 'error',
                  errorMsg: errorMessage,
                });
              }
            } else {
              resolve({
                status: 'error',
                errorMsg: 'Something went wrong, please try again later',
              });
            }
          })
          .catch((err) => {
            reject(err);
          });
      }),
  },
  modules: {},
});
