<template>
  <article class="contact-us verify" :class="{ mobile: isMobile }">
    <div v-if="!isMobile" class="bg">
      <img src="@/assets/images/Home/desktop_contact_bg.jpg" alt="" />
    </div>
    <div class="article-body text">
      <div data-aos="fade-in" class="container">
        <div class="content-wrap">
          <h2 class="header">Thank you. Please check your email to complete your submission.</h2>
          <p class="detail">We received your request, and we’ve sent a verification email to {{ userEmail }}.</p>
          <p class="detail">Click the link in the email within the next 48 hours to receive responses from us.</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'thank-you',
  metaInfo: {
    title: 'Verify your email contact us | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content: 'Check your email to complete this form and receive updates from us.',
      },
      { name: 'robots', content: 'noindex' },
    ],
    noscript: [
      {
        innerHTML:
          '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KXKDRHT" height="0" width="0" style="display:none;visibility:hidden"></iframe>',
        pbody: true,
      },
    ],
  },
  mounted() {
    /* eslint-disable */
    if (!window.PRERENDER_INJECTED) {
      console.log('applesauce');
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-KXKDRHT');
    }
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    userEmail() {
      return this.$store.state.email;
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  max-width: 550px;
  width: 100%;
  margin-left: 100px;
}

.article-body.text {
  padding: 0;
}

.article-body {
  padding: 0;
}

article {
  min-height: 100px;
  @media only screen and (min-width: 750px) {
    min-height: 650px;
  }
  @media only screen and (min-width: 1130px) {
    min-height: 1200px;
  }
}

.contact-us.mobile {
  padding: 50px 0 !important;
  .content-wrap {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}
</style>
