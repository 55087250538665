<template>
  <div class="main-wrap nevi">
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section-global">
      <HeroWithContentOverlay :maxWidth="1920" fullWidthMobile>
        <template #image>
          <img
            v-if="!isPhone && !isMobile"
            alt=""
            src="@/assets/images/Nevi/nevi_hero_desktop_EA_Baker_Gen4144.png"
            style="width: 100%; height: 100%; border-radius: 10px"
          />
          <img
            v-else-if="isMobile && !isPhone"
            alt=""
            src="@/assets/images/Nevi/nevi_hero_desktop_EA_Baker_Gen4144.png"
            style="width: 100%; height: 100%; border-radius: 10px"
          />
          <img
            v-else-if="isPhone"
            alt=""
            src="@/assets/images/Nevi/nevi_hero_mobile_EA_Baker_Gen4144.png"
            style="width: 100%; height: 100%; border-radius: 0"
          />
        </template>
        <h1>
          Take Advantage of the National Electric Vehicle Infrastructure (NEVI) Formula Program with Help from Electrify
          America
        </h1>
      </HeroWithContentOverlay>
    </section>
    <section class="contact-section">
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">Attracting Electric Vehicle (EV) drivers is key to the success of your business.</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            NEVI incentive funds may cover up to 80% of the costs to install DC fast charging stations at eligible
            locations.
          </p>
        </div>
      </div>
    </section>
    <section class="two-column-section flex-column">
      <div class="two-column-wrap">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_1.png"
              alt="Close-up of car being charged at Electrify America station."
            />
            <img
              v-if="isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_1.png"
              alt="Close-up of car being charged at Electrify America station."
            />
          </div>
          <div class="content">
            <h2 class="header">What is the NEVI Program?</h2>
            <p class="detail">
              Created by the federal government’s Infrastructure Investment and Jobs Act (IIJA), the NEVI program will
              provide $5 billion over ﬁve years for states to strategically deploy EV charging stations and establish an
              interconnected network to facilitate data collection, access, and reliability.
            </p>
            <p>
              <small>
                Information is provided for educational purposes only; this webpage and the information contained within
                has not been reviewed or endorsed by the U.S. Department of Transportation or the United States
                Government.
              </small>
            </p>
            <a
              href="https://www.fhwa.dot.gov/bipartisan-infrastructure-law/nevi_formula_program.cfm"
              rel="noopener"
              target="_blank"
              class="button button-link text-transform-none"
              >Learn more about bipartisan infrastructure law</a
            >
          </div>
        </div>
      </div>
      <div class="two-column-wrap">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_2.png"
              alt="Car connected to chargin station."
            />
            <img
              v-if="isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_2.png"
              alt="Car connected to chargin station."
            />
          </div>
          <div class="content">
            <h2 class="header">
              NEVI could fund up to 80% of eligible costs to deploy your EV charging station.*
              <reference-link num="1">
                Source:
                <a href="https://afdc.energy.gov/laws/12744" rel="noopener" target="_blank"
                  >Alternative Fuels Data Center: National Electric Vehicle Infrastructure (NEVI) Formula Program</a
                >
              </reference-link>
            </h2>
            <decorative-list class="decorative-list--multiline">
              <li>
                The NEVI program funds charging station deployments along designated Alternative Fuel Corridors (AFCs).
                Installing Ultra-Fast, reliable charging stations is critical to the country’s EV adoption and could
                drive environmental and economic beneﬁts for generations.
                <reference-link num="2">
                  Source:
                  <a
                    href="https://www.fhwa.dot.gov/environment/alternative_fuel_corridors/all_corridors/"
                    rel="noopener"
                    target="_blank"
                    >All Corridors - Alternative Fuel Corridors - Environment - FHWA (dot.gov)</a
                  >
                </reference-link>
              </li>
              <li>
                Investing In Ultra-Fast charging on your property opens the opportunity for EV drivers to be in your
                store for 20+ minutes while their vehicle charges—driving incremental revenue for your business.
                <reference-link num="3">
                  Source:
                  <a
                    href="https://www.transportation.gov/rural/ev/toolkit/ev-basics/charging-speeds"
                    rel="noopener"
                    target="_blank"
                    >Charger Types and Speeds</a
                  >
                </reference-link>
              </li>
              <li>
                With nearly half of consumers already stopping in a convenience store when buying gas, you could see
                major benefits from the amount of time EV drivers spend in your business while waiting for their car to
                charge.
              </li>
            </decorative-list>
            <p>
              <small
                >Information is provided for educational purposes only; this webpage and the information contained
                within has not been reviewed or endorsed by the U.S. Department of Transportation or the United States
                Government.</small
              >
            </p>
            <a
              href="https://www.fhwa.dot.gov/bipartisan-infrastructure-law/nevi_formula_program.cfm"
              rel="noopener"
              target="_blank"
              class="button button-link text-transform-none"
              >Learn more about bipartisan infrastructure law</a
            >
          </div>
        </div>
      </div>
      <div class="two-column-wrap" id="scale">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_3.png"
              alt="Car connected to charging station."
            />
            <img
              v-if="isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_3.png"
              alt="Car connected to charging station."
            />
          </div>
          <div class="content">
            <h2 class="header">Outfitting your property with EV charging could:</h2>
            <decorative-list>
              <li>Increase the time customers spend on your property</li>
              <li>Retain customers as EVs gain greater share of the automobile market</li>
              <li>Show your commitment to sustainability</li>
              <li>Create customer loyalty and repeat visits</li>
            </decorative-list>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-section">
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">
            The NEVI Program presents a once in a generation opportunity to install charging stations at your business—a
            golden opportunity amidst the rising EV market.
          </h2>
          <hr class="line-break" aria-hidden="true" />
          <router-link class="button" :to="{ name: 'contact-us-en' }"
            >Seeking Nevi insights?<Br />Share Your details here</router-link
          >
        </div>
      </div>
    </section>
    <section class="contact-section bg-white why-electrify">
      <div class="image">
        <img
          v-if="!isMobile"
          src="@/assets/images/Nevi/nevi_teaser_desktop_why_electrify.png"
          alt="an Electrify America charging location at dusk displaying six chargers pictured from the corner of the lot"
          style="width: 100%"
        />
        <img
          v-if="isMobile"
          src="@/assets/images/Nevi/nevi_teaser_mobile_why_electrify.png"
          alt="an Electrify America charging location at dusk displaying six chargers pictured from the corner of the lot"
          style="width: 100%"
        />
      </div>
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">Why Electrify America</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail md-width">
            Electrify America oﬀers a turnkey solution to building and maintaining a charging station on your property.
            With cutting-edge technology and analysis expertise, Electrify America is the right choice to guide you
            through the NEVI application process.
            <br /><br />
            Electrify America is not only the nation’s largest open Ultra-Fast charging network, but also the most
            experienced network to help you meet NEVI requirements, having
            <strong>already installed 85% of the NEVI-compliant chargers in the country.</strong>
          </p>
        </div>
      </div>
    </section>
    <section class="two-column-section flex-column">
      <div class="two-column-wrap network-map" id="network-map">
        <div v-bind:class="{ 'flex-column': isMobile, 'flex-row': !isMobile }" class="container">
          <div class="image">
            <figure class="stats__map">
              <img
                :class="{ 'desktop-img': !isMobile, 'mobile-img': isMobile }"
                srcset="../assets/images/Home/usaMap.gif 840w, ../assets/images/Home/usaMap@2x.gif 1680w"
                sizes="(min-width: 750px) 840px,
                100vw"
                src="../assets/images/Home/usaMap@2x.gif"
                alt="Map of America with hundreds of light blue dots denoting stations in operation or development as of August 2022. The same map has over a hundred light green dots denoting planned station locations by 2026. Network map coverage for illustrative purposes only."
              />
              <caption>
                <div class="caption--current">Current public DC fast charging stations as of August 2022</div>
                <div class="caption--planned">Planned future public DC fast charging stations as of 2026</div>
              </caption>
            </figure>
          </div>
          <div class="content">
            <h2 class="header">Explore the largest public fast charging network in the U.S.</h2>
            <p class="detail">
              Electrify America has 1,800 total charging stations with over 10,000 planned chargers in the United States
              and Canada by 2026.
            </p>
          </div>
        </div>
      </div>
      <div class="two-column-wrap" id="scale">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_4.png"
              alt="Charging station."
            />
            <img v-if="isPhone" src="../assets/images/Nevi/card_nevi_ElecrtrifyAmerica_4.png" alt="Charging station." />
          </div>
          <div class="content">
            <h2 class="header">Electrify America’s innovations include:</h2>
            <decorative-list class="decorative-list--multiline">
              <li>The first Plug & Charge compatible EV charging network in North America</li>
              <li>
                Been ranked “EV Charging Infrastructure Best-in-Test” for two years in a row, in 2020 and 2021
                <reference-link num="4">
                  Source:
                  <a
                    href="https://www.umlaut.com/en/stories/us-ev-charging-infrastructure-how-fast-and-how-convenient"
                    rel="noopener"
                    target="_blank"
                    >umlaut 2nd annual US Charging Infrastructure Benchmark together with Charged. - umlaut</a
                  >
                </reference-link>
              </li>
              <li>Largest open Direct Current Fast Charging (DCFC) network in the U.S.</li>
              <li>
                High-speed electric vehicle charging through a network compr exclusively of 150 kW & 350 kW chargers,
                with 350 kW being the fastest charging speed available on the market today
              </li>
            </decorative-list>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-section">
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">
            Installing a powerful, reliable charging station could prepare your business proﬁtability in this new era of
            travel.
          </h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail md-width">
            Learn more about how Electrify America can help you navigate complex NEVI Formula Program standards for
            grant funding.
          </p>
        </div>
      </div>
    </section>
    <section class="contact-section form">
      <div class="image">
        <img
          v-if="!isMobile"
          src="@/assets/images/Nevi/nevi_teaser_desktop_connect_with_nevi.png"
          alt="an Electrify America charging location at dusk displaying six chargers pictured from the corner of the lot"
          style="width: 100%"
        />
        <img
          v-else-if="isMobile"
          src="@/assets/images/Nevi/nevi_teaser_mobile_connect_with_nevi.png"
          alt="an Electrify America charging location at dusk displaying six chargers pictured from the corner of the lot"
          style="width: 100%"
        />
      </div>
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">Connect with a NEVI Program Expert</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail md-width">
            Electrify America is here to help you navigate unique NEVI requirements for your state’s grant funding and
            guide you through building a powerful, trusted charging station at your business from start to finish.
          </p>
          <router-link class="button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import HeroWithContentOverlay from '@/components/HeroWithContentOverlay/HeroWithContentOverlay.vue';
import ReferenceLink from '../components/ReferenceLink.vue';

export default {
  name: 'nevi',
  metaInfo: {
    title: 'NEVI Funding | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Take advantage of the NEVI Program with help from Electrify America to develop a compelling submission and explore installing an EV charging station on your property.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/nevi/' }],
  },
  components: { HeroWithContentOverlay, ReferenceLink },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-top: 25px;
  margin-bottom: 25px;

  small {
    font-size: 12px;
    font-weight: 400;
  }
}
.main-wrap {
  .hero-section-global {
    background-color: $c-primary-background;
    @media (min-width: 750px) {
      padding: 38px;
    }

    h1 {
      text-align: left;
      font-size: 32px;
      line-height: 36px;

      @media only screen and (min-width: 415px) {
        font-size: 48px;
        line-height: 52px;
      }

      @media only screen and (min-width: 750px) {
        font-size: 36px;
        line-height: 44px;
        margin-right: 32px;
      }

      @media only screen and (min-width: 1400px) {
        font-size: 68px;
        line-height: 72px;
      }
    }
  }
  .line-break {
    background-color: #87cc97;
  }

  .md-width {
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

  .detail {
    color: #011352;
    letter-spacing: 0.03px;
    font-size: 16px;
    font-weight: normal;
  }

  .button.button-link {
    font-size: 12px;
  }

  .two-column-section .two-column-wrap {
    padding: 60px 0;

    .container {
      .content {
        margin-top: 0;
      }
      .image img {
        max-width: 550px;
        max-height: none;
        margin: 0 auto;
      }

      @media screen and (max-width: 750px) {
        max-width: 540px;
        margin-left: auto;
        margin-right: auto;
      }

      .header {
        font-size: 24px;
        line-height: 33px;

        @media only screen and (min-width: 750px) {
          font-size: 35px;
          line-height: 42px;
        }
      }
    }

    &.network-map .container {
      .content {
        order: 2;

        @media only screen and (min-width: 1130px) {
          order: 1;
        }
      }
      .image {
        order: 1;

        @media only screen and (min-width: 1130px) {
          flex-basis: 31%;
          order: 2;
        }

        img {
          @media only screen and (min-width: 700px) {
            max-width: none;
            max-height: none;
            margin: 0;
          }
        }
      }
    }
  }
  .contact-section {
    background-color: #f5f5f5;

    &.bg-white {
      background-color: white;
    }

    .content {
      @media only screen and (min-width: 750px) {
        max-width: 1288px;
      }
    }

    &.form {
      .line-break {
        margin-left: auto;
        margin-right: auto;
      }

      .required {
        color: #eb3223;
      }

      .detail {
        margin-bottom: 60px;

        @media only screen and (min-width: 750px) {
          margin-bottom: 120px;
        }
      }

      input[type='submit'] {
        margin-left: auto;
        margin-right: auto;
        display: block;
      }

      .button:not(.button-link) {
        margin-bottom: 40px;

        @media only screen and (min-width: 750px) {
          margin-bottom: 50px;
        }
      }

      .button.button-link {
        font-size: inherit;
        display: inline;
      }

      p {
        span {
          display: inline-block;
          margin-bottom: 16px;
        }

        small {
          text-align: center;
          display: block;
        }
      }
    }
  }

  .decorative-list {
    li {
      background-position-y: 40%;
    }

    &--multiline {
      li {
        background-position-y: 12%;
      }
    }
  }

  .stats {
    &__map {
      display: flex;
      flex-direction: column;
      margin: 0;

      caption {
        align-items: center;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        font-weight: bold;
        font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
        margin: 32px auto;

        .caption--current,
        .caption--planned {
          &::before {
            content: '';
            border-radius: 50%;
            display: inline-block;
            height: 7px;
            margin-right: 9px;
            margin-bottom: 2px;
            width: 7px;
          }
        }

        .caption--current::before {
          background-color: #00afca;
        }
        .caption--planned::before {
          background-color: #76c585;
        }
      }

      img {
        align-self: center;
        margin: 30px 10px;
        max-width: 840px;
        width: 100%;
      }
    }
  }
}
</style>
