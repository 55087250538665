<template>
  <div class="main-wrap">
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section-global">
      <img
        v-if="!isPhone"
        src="@/assets/images/About/desktop_about_hero_img.png"
        alt="An employee replaces the charger at an Electrify America station"
      />
      <img
        v-if="isPhone"
        src="@/assets/images/About/mobile_about_hero_img.jpg"
        alt="An employee replaces the charger at an Electrify America station"
      />
      <div class="container">
        <div class="content flex-column">
          <h1 class="header header-light">About Electrify Commercial</h1>
          <hr class="line-break" aria-hidden="true" />
          <div class="about-content">
            <div class="header">Who we are</div>
            <p class="detail">
              Electrify Commercial provides end-to-end electric vehicle (EV) charging<br v-if="!isPhone" />
              solutions to help organizations meet the demands of the growing EV landscape.
            </p>
          </div>
          <div class="about-content">
            <div class="header">Our mission</div>
            <p class="detail">
              To expand electric-powered mobility across the country <br v-if="!isPhone" />
              by helping organizations reach their EV charging goals.
            </p>
          </div>
          <div class="about-content">
            <div class="header">What drives us</div>
            <p class="detail">
              Organizations want to develop EV charging solutions for their employees, customers, and the general
              public—but they could use some help to turn their vision into a reality. As the company behind the largest
              public DC Ultra-Fast charging network, our one-of-a-kind expertise can help organizations develop charging
              programs tailored to their unique needs—and shape the future of electric-powered mobility.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="image-gallery-global">
      <div class="container">
        <div class="image-wrap">
          <div class="one-image-row">
            <img
              v-if="!isPhone"
              src="@/assets/images/About/desktop_about_img.jpg"
              alt="An employee charges an electric bus at SacRT's Electrify America station"
            />
            <img
              v-if="isPhone"
              src="@/assets/images/About/mobile_about_img.jpg"
              alt="An employee charges an electric bus at SacRT's Electrify America station"
            />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'about',
  metaInfo: {
    title: 'About Us | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content:
          'Electrify Commercial provides end-to-end electric vehicle (EV) charging solutions to help organizations meet the demands of the growing EV landscape.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/about-us/' }],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.above(750);
    },
    isTiny() {
      return this.$resize && this.$mq.below(600);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
};
</script>

<style lang="scss" scoped>
.about-content {
  margin-bottom: 24px;
}
h1 {
  font-size: 36px;
  @media (min-width: 750px) {
    font-size: 50px;
  }
}
</style>
