<template>
  <div class="carousel" ref="carousel">
    <Splide
      @splide:inactive="actionEnded"
      @splide:autoplay:play="startPlay"
      @splide:autoplay:pause="startPause"
      :options="options"
      :has-track="false"
      ref="splide"
    >
      <SplideSlide v-if="$slots.slide1 && $slots.slide1.length"><slot name="slide1"></slot></SplideSlide>
      <SplideSlide v-if="$slots.slide2 && $slots.slide2.length"><slot name="slide2"></slot></SplideSlide>
      <SplideSlide v-if="$slots.slide3 && $slots.slide3.length"><slot name="slide3"></slot></SplideSlide>
      <SplideSlide v-if="$slots.slide4 && $slots.slide4.length"><slot name="slide4"></slot></SplideSlide>
      <template v-slot:controls>
        <div v-if="autoplay" class="splide__progress">
          <div class="splide__progress__bar"></div>
        </div>
        <div v-if="autoplay" class="splide__autoplay">
          <button @click="play" v-show="pause" class="splide__play"><play class="play" /></button>
          <button v-show="!pause" class="splide__pause"><pause /></button>
        </div>
      </template>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/splide.min.css';
import Play from '@/assets/images/SplideCarousel/play.svg';
import Pause from '@/assets/images/SplideCarousel/pause.svg';

export default {
  name: 'carousel',
  components: {
    Splide,
    SplideSlide,
    Play,
    Pause,
  },
  props: {
    autoplay: { type: Boolean, default: false, required: false },
    gap: { type: Number, default: 63, required: false },
    perPage: { type: Number, default: 3, required: false },
    pagination: { type: Boolean, default: false, required: false },
  },
  data() {
    return {
      options: {
        autoplay: !window.matchMedia('(prefers-reduced-motion: reduce)').matches && this.autoplay,
        gap: this.gap,
        interval: 6000,
        pagination: this.pagination,
        paginationDirection: 'ltr',
        paginationKeyboard: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        perPage: this.perPage,
        perMove: 1,
        rewind: true,
        speed: 1000,
        type: 'loop',
        waitForTransition: true,
      },
      pause: false,
    };
  },
  methods: {
    useFocusTrap() {
      const { carousel } = this.$refs;
      const notVisible = carousel.querySelectorAll('li:not(.is-visible)');
      const visible = carousel.querySelectorAll('li.is-visible');
      const pagination = carousel.querySelectorAll('li:not(.splide__slide)');

      const removeAttributes = (element, ...attributes) =>
        attributes.forEach((attribute) => element?.removeAttribute(attribute));
      const setAttributes = (element, attributes) => {
        Object.keys(attributes).forEach((attr) => {
          element.setAttribute(attr, attributes[attr]);
        });
      };
      const attributes = {
        inert: 'true',
        tabindex: '-1',
      };

      notVisible.forEach((el) => setAttributes(el, attributes));
      [...visible].forEach((el) => removeAttributes(el, 'inert', 'tabindex', 'aria-hidden'));
      [...pagination].forEach((el) => removeAttributes(el, 'inert', 'tabindex', 'aria-hidden'));
    },
    actionEnded() {
      this.$nextTick(() => {
        this.useFocusTrap();
      });
    },
    startPlay() {
      this.pause = false;
    },
    startPause() {
      this.pause = true;
    },
    play() {
      this.$refs.splide.splide.Components.Autoplay.play();
    },
  },
  mounted() {
    this.useFocusTrap();

    const { carousel } = this.$refs;
    const searchInput = carousel.querySelectorAll('input:not([inert])');

    const pauseOnFocus = () => {
      if (document.activeElement === searchInput[0]) {
        this.$refs.splide.splide.Components.Autoplay.pause();
      }
    };

    carousel.addEventListener('click', () => {
      pauseOnFocus();
    });
    carousel.addEventListener('keyup', () => {
      pauseOnFocus();
    });
  },
  watch: {
    $resize() {
      window.location.reload();
    },
  },
};
</script>
<style lang="scss" scoped>
.carousel {
  max-width: 1426px;
  margin: 0 auto;
  @media (min-width: 750px) {
    padding: 0 24px;
  }
}

button {
  display: inline;
  position: relative;
  background-color: #ccc;
  border-radius: 50%;
  padding: 6px;
  border: none;
  cursor: pointer;

  svg {
    position: relative;
    left: 0.35px;
    top: 1.5px;
    &.play {
      left: 2px;
    }
  }
}
</style>
