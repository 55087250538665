<template>
  <article class="contact-us verify" :class="{ mobile: isMobile }">
    <img
      class="banner"
      src="@/assets/images/Global/contact_us_banner.png"
      alt="Electrify America chargers under a lighted canopy at night"
    />
    <div class="article-body text">
      <div data-aos="fade-in" class="container">
        <div class="content-wrap">
          <h2 class="header">You are unsubscribed</h2>
          <p class="detail">You have successfully unsubscribed from our mailing list.</p>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  name: 'thank-you',
  metaInfo: {
    title: 'Unsubscribe | Electrify Commercial',
    meta: [
      {
        name: 'description',
        content: 'You have successfully unsubscribed from our email list.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  computed: {
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
  },
};
</script>

<style scoped lang="scss">
.content-wrap {
  max-width: 550px;
  width: 100%;
  margin-left: 100px;
}

.article-body.text {
  padding: 0;
}

.article-body {
  padding: 0;
}

article {
  min-height: 100px;
  @media only screen and (min-width: 750px) {
    min-height: 650px;
  }
  @media only screen and (min-width: 1130px) {
    min-height: 1200px;
  }
}

.verify {
  color: #0b0f4d;
  .banner {
    width: 100vw;
    display: block;
    margin-bottom: 50px;
  }
  .header {
    font-size: 80px;
    font-weight: 300;
    line-height: 1.1 !important;
  }
  .detail {
    font-size: 16px;
    font-weight: normal;
  }
}

.contact-us.mobile {
  .content-wrap {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
  }
}
</style>
