import { render, staticRenderFns } from "./VerifyEmailSignup.vue?vue&type=template&id=6321d64d&scoped=true&"
import script from "./VerifyEmailSignup.vue?vue&type=script&lang=js&"
export * from "./VerifyEmailSignup.vue?vue&type=script&lang=js&"
import style0 from "./VerifyEmailSignup.vue?vue&type=style&index=0&id=6321d64d&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6321d64d",
  null
  
)

export default component.exports