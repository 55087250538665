import axios from 'axios';
import VueAxios from 'vue-axios';
import Vue from 'vue';

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_SALESFORCE_URL;
    Vue.axios.defaults.headers.common['Content-Type'] = 'application/json';
    // Vue.axios.defaults.headers.common['Cache-Control'] = 'no-store, no-cache, must-revalidate';
    // Vue.axios.defaults.headers.common.Pragma = 'no-cache';
  },
  get(resource, params) {
    return Vue.axios.get(`${resource}`, { params });
  },

  post(resource, params, queryParams) {
    return Vue.axios.post(`${resource}`, params, queryParams);
  },

  patch(resource, params) {
    return Vue.axios.patch(`${resource}`, params);
  },

  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  delete(resource, params) {
    return Vue.axios.delete(`${resource}`, params);
  },
};

export default ApiService;
