import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store/index';

import Home from '@/views/Home.vue';
import Advantages from '@/views/Advantages.vue';
import Solutions from '@/views/Solutions.vue';
import FeaturedProjects from '@/views/FeaturedProjects.vue';
import About from '@/views/About.vue';
import ThankYou from '@/views/ThankYou.vue';
import ContactUs from '@/views/Contact/ContactUs.vue';
import VerifyEmailContactUs from '@/views/VerifyEmailContactUs.vue';
import VerifyEmailSignup from '@/views/VerifyEmailSignup.vue';
import Unsubscribe from '@/views/Unsubscribe.vue';
import ExpiredEmail from '@/views/ExpiredEmail.vue';
import Terms from '@/views/Terms.vue';
import SocialMediaHouseRules from '@/views/SocialMediaHouseRules.vue';
import Redirects from '@/router/Redirects';
import Nevi from '@/views/Nevi.vue';

Vue.use(VueRouter);

let routes = [
  {
    path: '/',
    name: 'home-en',
    component: Home,
  },
  {
    path: '/our-advantage/',
    name: 'advantages-en',
    pathToRegexpOptions: { strict: true },
    component: Advantages,
  },
  {
    path: '/solutions/',
    name: 'solutions-en',
    pathToRegexpOptions: { strict: true },
    component: Solutions,
  },
  {
    path: '/featured-project-1/',
    name: 'feature-01-en',
    pathToRegexpOptions: { strict: true },
    component: FeaturedProjects,
  },
  {
    path: '/NEVI/',
    name: 'nevi-en',
    pathToRegexpOptions: { strict: true },
    component: Nevi,
  },
  {
    path: '/about-us/',
    name: 'about-us-en',
    pathToRegexpOptions: { strict: true },
    component: About,
  },
  {
    path: '/terms/',
    name: 'terms-en',
    pathToRegexpOptions: { strict: true },
    component: Terms,
  },
  {
    path: '/thank-you/',
    name: 'thank-you-en',
    pathToRegexpOptions: { strict: true },
    component: ThankYou,
  },
  {
    path: '/social-media-house-rules/',
    name: 'social-media-house-rules-en',
    pathToRegexpOptions: { strict: true },
    component: SocialMediaHouseRules,
  },
  {
    path: '/email-verification-contact-us/',
    name: 'email-verification-contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailContactUs,
  },
  {
    path: '/email-verification/',
    name: 'email-verification-en',
    pathToRegexpOptions: { strict: true },
    component: VerifyEmailSignup,
  },
  {
    path: '/unsubscribe/',
    name: 'unsubscribe-en',
    pathToRegexpOptions: { strict: true },
    component: Unsubscribe,
  },
  {
    path: '/expired-email/',
    name: 'expired-email-en',
    pathToRegexpOptions: { strict: true },
    component: ExpiredEmail,
  },
  {
    path: '/contact-us/',
    name: 'contact-us-en',
    pathToRegexpOptions: { strict: true },
    component: ContactUs,
  },
];
routes = routes.concat(Redirects);

/**
 * All Routes redirect `/path` to `/path/`
 */
routes.forEach((route) => {
  if (route.name && route.path.substr(-1) === '/') {
    routes.push({
      path: route.path.slice(0, -1),
      redirect: {
        name: route.name,
      },
    });
  }
});

routes.forEach((route) => {
  if (route.name) {
    routes.push({
      path: route.path.substr(-1) === '/' ? `${route.path}index.html` : `${route.path}/index.html`,
      redirect: {
        name: route.name,
      },
    });
  }
});

routes.push({
  path: '*',
  redirect: '/',
});

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const position = { selector: to.hash };

      // specify offset of the element to account for header
      position.offset = { y: 100 };

      return position;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  if (to.params.lang === 'fr') {
    Store.commit('setLang', 'fr');
  } else if (to.params.lang) {
    router.push({ name: 'home-en' });
  }

  const defaultName = process.env.VUE_APP_SITE === 'us' ? ' | Electrify America' : ' | Electrify Canada';

  if (to.meta && to.meta.title) document.title = `${to.meta.title}${defaultName}`;
  else document.title = process.env.VUE_APP_SITE === 'us' ? 'Electrify America' : 'Electrify Canada';

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));

  if (!to.meta || !to.meta.metaTags) return next();

  to.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute('name', key);
        tag.setAttribute('content', tagDef[key]);
      });

      tag.setAttribute('data-vue-router-controlled', '');

      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  window.analytics.page(to.name);

  return next();
});

export default router;
