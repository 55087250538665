<template>
  <ul>
    <slot />
  </ul>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
li {
  background: url('../assets/images/DecorativeList/blue_bolt.png') no-repeat left center;
  min-height: 2.1rem;
  padding-left: 2rem;
  padding-top: 16px;
  padding-bottom: 16px;
  color: #000;
}
</style>
