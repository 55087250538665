<template>
  <div :class="{ webkit: isChrome }" class="main-wrap">
    <router-link v-if="!isMobile" class="button fixed-button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
    <section class="hero-section-global">
      <img
        v-if="!isPhone"
        src="@/assets/images/OurAdvantage/our_advantage_banner.png"
        alt="Close-up of Electrify America charger angle"
      />
      <img
        v-if="isPhone"
        src="@/assets/images/OurAdvantage/our_advantage_banner.png"
        alt="Close-up of Electrify America charger angle"
      />
      <div class="container">
        <div class="content flex-column">
          <h1 class="bold">Our industry experience defines the Electrify Commercial edge</h1>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            How’d we become the fastest, trusted, chosen, customer-centric network we are today? <br />
            By overcoming the challenges of electrification firsthand.
          </p>
          <p class="detail">We know what to do (and how to do it) because we’ve already done it for ourselves.</p>
        </div>
      </div>
    </section>
    <section class="two-column-section flex-column customer-center">
      <div class="two-column-wrap" id="innovation">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/OurAdvantage/our_advantage1.png"
              alt="Electrify America chargers in use."
            />
            <img
              v-if="isPhone"
              src="../assets/images/OurAdvantage/our_advantage1.png"
              alt="Electrify America chargers in use."
            />
          </div>
          <div class="content">
            <h2 class="header">Beyond the customer contact center</h2>
            <p class="details">
              World-class testing and rigorous quality assurance at our Center of Excellence help anticipate roadblocks.
            </p>
            <decorative-list>
              <li>Interoperability in state-of-the-art Center of Excellence testing lab</li>
              <li>
                U.S.-based customer contact center operates 24/7 via phone, email, and chat to provide personalized
                support
              </li>
              <li>Program management and diagnostic support</li>
            </decorative-list>
          </div>
        </div>
      </div>
      <div class="two-column-wrap innovation" id="expertise">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img v-if="!isPhone" src="../assets/images/OurAdvantage/our_advantage2.png" alt="Expert at work." />
            <img v-if="isPhone" src="../assets/images/OurAdvantage/our_advantage2.png" alt="Expert at work." />
          </div>
          <div class="content">
            <h2 class="header">Constant innovation</h2>
            <p class="details">
              We innovate at every opportunity, delivering on the promises of electrification for our clients (and
              yours).
            </p>
            <decorative-list>
              <li>
                Energy management with 100+ battery energy storage systems deployed nationwide with plans for additional
                future deployments
              </li>
              <li>Only network to be Plug & Charge (ISO 15118) enabled*</li>
              <li>Own and operate the most 150 kW and 350 kW chargers*</li>
            </decorative-list>
            <p class="details">*Accurate as of August 2021</p>
          </div>
        </div>
      </div>
      <div class="two-column-wrap network" id="scale">
        <div v-bind:class="{ 'flex-column': isPhone, 'flex-row': !isPhone }" class="container">
          <div class="image">
            <img
              v-if="!isPhone"
              src="../assets/images/OurAdvantage/our_advantage3.png"
              alt="Engineer working on charging station."
            />
            <img
              v-if="isPhone"
              src="../assets/images/OurAdvantage/our_advantage3.png"
              alt="Engineer working on charging station."
            />
          </div>
          <div class="content">
            <h2 class="header">Unmatched network</h2>
            <p class="details">We have the means and connections to make plans and make them happen.</p>
            <decorative-list>
              <li>Site acquisition team solely dedicated to finding and securing best possible locations</li>
              <li>
                Deep knowledge, from siting to energizing charging stations and coordinating hundreds of projects
                simultaneously
              </li>
              <li>Relationships with hundreds of utilities and local planning authorities, coast-to-coast</li>
            </decorative-list>
          </div>
        </div>
      </div>
    </section>
    <section class="contact-section">
      <div class="container">
        <div class="content flex-column">
          <h2 class="header">Electric excellence is possible. Let’s make it happen.</h2>
          <hr class="line-break" aria-hidden="true" />
          <p class="detail">
            All good things (like your ideal customized electric vehicle charging program) begin with a question.
          </p>
          <router-link class="button" :to="{ name: 'contact-us-en' }">Contact Us</router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import AOS from 'aos';
import DecorativeList from '../components/DecorativeList.vue';

export default {
  name: 'advantages',
  metaInfo: {
    title: 'Our Advantage | Electrify Commercial | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Our innovation, expertise, and scale allow us to provide you with charging solutions that will drive your organization into the future of mobility.',
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrify-commercial.com/our-advantage/' }],
  },
  components: { DecorativeList },
  created() {},
  data() {
    return {
      advantageOne: null,
      advantageTwo: null,
      advantageThree: null,
      advantageOneDetails: [
        "350 kW chargers, the fastest widely deployed technology available, are made to serve today's top-performing EVs as well as the high-power and longer-range EVs of the future",
        'The Electrify America Center of Excellence laboratory, which brings together EV experts to develop and test our next-generation charging technology',
        'Charging concepts and solutions for fleet vehicles through agreements with Lyft and Gig',
        'Charging for large-scale, heavy-duty vehicles like public buses, distribution center, port, and over-the-road charging solutions',
        'Actively collaborating to develop automatic robotic charging for self-driving EVs of the future',
      ],
      advantageTwoDetails: [
        'Site acquisition tools, processes, and relationships',
        'Extensive hardware and supply-chain expertise',
        'App integration, software, and networking capabilities',
        'Optimized site-configuration layouts',
        'Nationwide network of service providers',
        'Utility service management experience',
        'Comprehensive maintenance program',
        '24/7 customer support center',
      ],
      advantageThreeDetails: [
        'EV charging stations in 44 states (and counting), supporting 86 metropolitan areas, with major U.S. cross-country highway stations averaging only 70 miles apart',
        'Approximately 800 charging stations with about 3,500 fast chargers across 93 U.S. metros/45 states and key highway routes expected by the end of 2021',
        'New stations added to the network every week',
        'Agreements with four industry-leading charger hardware manufacturers',
      ],
      advantages: [
        {
          mobileIMG: require('../assets/images/Home/mobilehomepagescale.png'),
          desktopIMG: require('../assets/images/Home/desktophomepagescale.png'),
          alt: 'Arizona Proving Grounds chargers at dusk',
          title: 'Scale',
          subheader: 'Our vast nationwide network includes:',
          summary:
            'Our expansive network and nationwide presence means we have the capability to complete nearly any project. No matter the size of your endeavor or where in the United States you are located, fast and flexible EV charging solutions are within your reach.',
        },
      ],
    };
  },
  computed: {
    isChrome() {
      return navigator.appVersion.indexOf('Chrome/') !== -1;
    },
    isMobile() {
      return this.$resize && this.$mq.below(1130);
    },
    isBig() {
      return this.$resize && this.$mq.above(750);
    },
    isTiny() {
      return this.$resize && this.$mq.below(600);
    },
    isPhone() {
      return this.$resize && this.$mq.below(750);
    },
  },
  methods: {
    expandDetail(index) {
      if (index === 1) {
        this.advantageOne = !this.advantageOne;
      } else if (index === 2) {
        this.advantageTwo = !this.advantageTwo;
      } else {
        this.advantageThree = !this.advantageThree;
      }
    },
  },
  mounted() {
    setTimeout(() => {
      AOS.refresh();
    }, 300);
  },
};
</script>

<style lang="scss" scoped>
.webkit .list-style {
  text-indent: -22px;
}

.hero-section-global {
  .content {
    @media only screen and (min-width: 750px) {
      max-width: 900px;
    }
  }
}

.main-wrap {
  font-family: 'Ubuntu', Arial, Helvetica, sans-serif;
  color: #011452;
  h1 {
    font-size: 55px;
    @media screen and (max-width: 750px) {
      line-height: 1.3;
      font-size: 40px;
    }
  }
  .line-break {
    background-color: #87cc97;
  }
  .detail {
    color: #000000;
    letter-spacing: 0.03px;
    font-size: 16px;
    font-weight: normal;
  }
  .two-column-section {
    .image img {
      width: 450px;
      max-width: 80%;
      margin: 60px auto 0 auto;
      @media screen and (max-width: 1000px) {
        margin-top: 20%;
        max-width: 80%;
      }
      @media screen and (max-width: 750px) {
        margin-top: 30px;
      }
    }
  }
  .customer-center,
  .network {
    background-color: #fff !important;
  }
  .innovation,
  .network,
  .contact-section {
    background-color: #f5f5f5;
  }
  .contact-section {
    .header {
      font-size: 45px;
      line-height: 1.2;
    }
  }
  .customer-center,
  .innovation,
  .network {
    .header {
      line-height: 1.1;
      margin-bottom: 0;
      margin-top: -10px;
      font-size: 40px;
      @media screen and (max-width: 750px) {
        margin-top: 30px;
      }
    }
  }
  .details {
    color: #000;
    font-size: 16px;
  }
}
</style>
